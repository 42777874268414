import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";

// core styles
import "./scss/volt.scss";

// vendor styles
import "@fortawesome/fontawesome-free/css/all.css";
import "react-datetime/css/react-datetime.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { QueryClient, QueryClientProvider } from "react-query";
import HomePage from "./pages/HomePage";
import AuthContextProvider from "./context/AuthContext";
import NotificationContextProvider from "./context/NotificationContext";
// import { disableReactDevTools } from "@fvilers/disable-react-devtools";

// if (process.env.NODE_ENV === "production") {
//   disableReactDevTools();
// }
// debugger;

const queryClient = new QueryClient();
ReactDOM.render(
  <HashRouter>
    <AuthContextProvider>
      <NotificationContextProvider>
        <QueryClientProvider client={queryClient}>
          <HomePage />
        </QueryClientProvider>
      </NotificationContextProvider>
    </AuthContextProvider>
  </HashRouter>,
  document.getElementById("root")
);

if (module.hot) {
  module.hot.accept();
}
