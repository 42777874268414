export const BACKEND_URL =
  process.env.NODE_ENV === "production"
    ? "https://api.tnpnsut.in"
    : "http://localhost:1337";

export const inputFields = [
  // {
  //   label: "Name",
  //   name: "name",
  //   type: "text",
  // },
  // {
  //   label: "Gender",
  //   name: "gender",
  //   type: "select",
  //   options: [
  //     { value: "", name: "Select Gender" },
  //     { value: "Male", name: "Male" },
  //     { value: "Female", name: "Female" },
  //     { value: "Other", name: "Other" },
  //   ],
  // },
  // {
  //   label: "Date of Birth",
  //   name: "dob",
  //   type: "date",
  // },
  // {
  //   label: "Blood Group",
  //   name: "blood_group",
  //   type: "text",
  // },
  {
    label: "Roll Number",
    name: "username",
    type: "text"
  },
 
  
  {
    label: "Category",
    name: "category",
    type: "select",
    options: [
      { value: "", name: "Select Category" },
      { value: "GENERAL", name: "GENERAL" },
      { value: "EWS", name: "EWS" },
      { value: "OBC", name: "OBC" },
      { value: "SC", name: "SC" },
      { value: "ST", name: "ST" },
      { value: "PWD", name: "PWD" }
    ]
  },
  {
    label: "Degree",
    name: "degree",
    type: "select",
    options: [
      { value: "", name: "Select Degree" },
      { value: "BTech", name: "BTech" },
      { value: "MTech", name: "MTech" },
      { value: "MBA", name: "MBA" },
      { value: "BBA", name: "BBA" },
      { value: "MSc", name: "MSc" },
      { value: "BDesign", name: "BDesign" },
      { value: "MA", name: "MA" }
    ]
  },
  {
    label: "Batch",
    name: "batch",
    type: "select",
    options: [
      { value: "", name: "Select Batch" },
      { value: "2025", name: "2025" },
      { value: "2026", name: "2026" },
      { value: "2027", name: "2027" },
      { value: "2028", name: "2028" },
      { value: "2029", name: "2029" },
      { value: "2030", name: "2030" }
    ]
  },
  {
    label: "Branch",
    name: "branch",
    type: "select",
    options: [
      { value: "", name: "Select Branch" },
      { value: "CSE", name: "CSE" },
      { value: "CSAI", name: "CSAI" },
      { value: "CSDS", name: "CSDS" },
      { value: "CSDA", name: "CSDA" },
      { value: "MAC", name: "MAC" },
      { value: "CIOT", name: "CIOT" },
      { value: "IT", name: "IT" },
      { value: "ITNS", name: "ITNS" },
      { value: "IIOT", name: "IIOT" },
      { value: "ECE", name: "ECE" },
      { value: "EIOT", name: "EIOT" },
      { value: "ECAM", name: "ECAM" },
      { value: "ICE", name: "ICE" },
      { value: "EE", name: "EE" },
      { value: "ME", name: "ME" },
      { value: "MPAE", name: "MPAE" },
      { value: "MEEV", name: "MEEV" },
      { value: "BT", name: "BT" },
      { value: "CIVIL", name: "CIVIL" },
      { value: "BBA", name: "BBA" },
      { value: "MBA", name: "MBA" },
      { value: "BDesign", name: "BDesign" },
      { value: "AI", name: "AI" },
      { value: "English", name: "English" },
      { value: "Psychology", name: "Psychology" },
      { value: "Physics", name: "Physics" },
      { value: "Mathematics", name: "Mathematics" },
      { value: "Chemistry", name: "Chemistry" },
      { value: "Bioinformatics", name: "Bioinformatics" },
      { value: "Environment", name: "Environment" },
      { value: "EVLSI", name: "EVLSI" }
    ]
  },
  // {
  //   label: "Personal Email",
  //   name: "personal_email",
  //   type: "email",
  // },
  // {
  //   label: "Contact Number",
  //   name: "contact_no",
  //   type: "number",
  //   minLength: 10,
  //   maxLength: 10,
  // },
  // {
  //   label: "Permanent Contact Number",
  //   name: "perm_contact_no",
  //   type: "number",
  //   minLength: 10,
  //   maxLength: 10,
  // },

  // {
  //   label: "Current Address",
  //   name: "current_address",
  //   type: "text",
  // },
  // {
  //   label: "Permanent Address",
  //   name: "perm_address",
  //   type: "text",
  // },
  {
    label: "Class 10 Percentage",
    name: "class_10",
    type: "number",
    placeholder: "ex: 90"
  },
  {
    label: "Class 12 Percentage",
    name: "class_12",
    type: "number",
    placeholder: "ex: 90"
  },
  // {
  //   label: "Aadhaar Number",
  //   name: "addhar",
  //   type: "text",
  // },
  // {
  //   label: "Pan Card Number",
  //   name: "pan",
  //   type: "text",
  // },
  {
    label: "IMS Password",
    name: "password",
    type: "password",
    placeholder: "password"
  },
  {
    label: "Captcha",
    name: "captcha",
    type: "captcha",
    placeholder: "Fill the captcha"
  },
];

export const newRegisterFormData = {
  // gender: "",
  username: "",
  password: "",
  captcha: "",
  category: "",
  degree: "",
  batch: "",
  branch: "",
  // contact_no: "",
  // perm_address: "",
  class_10: "",
  class_12: "",
  // name: "",
  // current_address: "",
  // perm_contact_no: "",
  // dob: "",
  // addhar: "",
  // blood_group: "",
  // pan: "",
  // personal_email: "",
};
